<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    width="100%"
    @click:outside="$emit('update:dialog', false)"
    scrollable
  >
    <v-card :disabled="loading" :loading="loading">
      <v-card-title class="subtitle-1 lightgray">
        {{ !add ? config.campo : "Adicionar Campo" }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialog', false)">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-4" style="heigth: 400px">
        <v-container>
          <v-row>
            <!-- Nome Campo -->
            <v-col cols="12" md="6">
              <v-text-field
                v-model="config.campo"
                label="Nome Campo"
                dense
                filled
                hide-details
              ></v-text-field>
            </v-col>

            <!-- Grupos -->
            <v-col cols="12" md="6">
              <v-select
                v-model="config.grupo"
                :items="configGrupos"
                clearable
                dense
                filled
                hide-details
                label="Grupo"
              ></v-select>
            </v-col>

            <!-- Categoria -->
            <v-col cols="12" md="6">
              <v-select
                v-model="config.categoria"
                :items="configCategorias"
                clearable
                dense
                filled
                hide-details
                label="Categoria"
              ></v-select>
            </v-col>

            <!-- Tipo -->
            <v-col cols="12" md="6">
              <v-select
                v-model="config.tipo"
                :items="configTipos"
                clearable
                dense
                filled
                hide-details
                label="Tipo"
              ></v-select>
            </v-col>

            <!-- Valores -->
            <v-col cols="12">
              <!-- Valor tipo {{ text }} -->
              <v-text-field
                v-if="config.tipo === 'text'"
                v-model="config.valor"
                label="Valor"
                dense
                filled
                hide-details
              ></v-text-field>

              <!-- Valor tipo {{ textarea }} -->
              <v-textarea
                v-if="config.tipo === 'textarea'"
                v-model="config.valor"
                label="Valor"
                auto-grow
                :rows="2"
                dense
                filled
                hide-details
              ></v-textarea>

              <!-- Valor tipo {{ boolean }} -->
              <v-card
                v-if="config.tipo === 'boolean'"
                flat
                style="background-color: rgba(0, 0, 0, 0.06); border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important; border-bottom-left-radius: 0px !important; border-bottom-right-radius: 0px !important;"
                height="52px"
                class="pa-3 mt-4 d-flex justify-space-between align-center"
              >
                <v-subheader
                  style="height: auto !important"
                  class="ma-0 pa-0 body-1"
                >
                  Valor
                </v-subheader>

                <v-switch
                  class="ma-0 pa-0"
                  hide-details
                  false-value="0"
                  true-value="1"
                  v-model="config.valor"
                ></v-switch>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          small
          dark
          color="toolbar"
          @click="add ? addConfig() : updateConfig()"
          :disabled="disable"
        >
          Salvar
          <v-icon right>save</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "NovoCampo",
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    add: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      configTipos: ["text", "boolean", "textarea"],
      configGrupos: ["feedback", "whatsapp", "modulos"],
      configCategorias: ["whatsapp", "modulos"],
      config: {},
      loading: false,
    };
  },
  computed: {
    disable() {
      let value = true;
      if (
        this.config.campo &&
        this.config.tipo &&
        this.config.grupo &&
        this.config.categoria
      ) {
        value = false;
      }
      return value;
    },
  },
  watch: {
    item() {
      if (this.item) {
        this.config = this.lodash.cloneDeep(this.item);
      }
    },
  },
  methods: {
    ...mapActions("Tocsconfig", {
      putConfig: "putConfig",
      postConfig: "postConfig",
    }),
    updateConfig() {
      this.loading = true;
      this.putConfig({ id: this.config.id, config: this.config })
        .then(() => {
          this.$emit("update:dialog", false);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addConfig() {
      this.loading = true;
      this.postConfig(this.config)
        .then(() => {
          this.config = {};
          this.$emit("update:dialog", false);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
